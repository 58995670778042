"use client";

import { Page } from "next";
import { useEffect } from "react";
import { deleteTokenCookie } from "../../app/actions";

const UnauthorizedError: Page = () => {
    useEffect(() => {
        deleteTokenCookie().then(() => {
            window.location.reload();
        });
    }, []);

    return <></>;
};

export default UnauthorizedError;
